import React, { useEffect, useState } from "react";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { usePrivy, useWallets } from "@privy-io/react-auth";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Feed from "./components/Feed";
import BottomBar from "./components/BottomBar";
import AirdropPage from "./components/AirdropPage";
import LoginPage from "./components/LoginPage";
import InviteCodePage from "./components/InviteCodePage";
import CreateWalletPage from "./components/CreateWalletPage";
import { getUser } from "./util/backendApi";
import WalletPage from "./components/WalletPage";
import TrendingMemes from "./components/Trending";
import MyMemes from "./components/MyMemes";
import NotificationPage from "./components/NotificationPage";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loadingUserInfo, setLoadingUserInfo] = useState(true);
  const [loadingWallet, setLoadingWallet] = useState(true);
  const [userInfo, setUserInfo] = useState(null); // user info from firestore
  const { ready, authenticated, user, getAccessToken } = usePrivy();
  const { wallets } = useWallets();
  const embeddedWallet = wallets.find(
    (wallet) => wallet.walletClientType === "privy"
  );

  useEffect(() => {
    setLoadingUserInfo(true);
    const loginFlow = ["/Login", "/InviteCode"];
    if (loginFlow.includes(location.pathname)) {
      setLoadingUserInfo(false);
    } else {
      if (ready && !authenticated) {
        setLoadingUserInfo(false);
        navigate("/Login");
      }
      if (ready && authenticated) {
        getAccessToken()
          .then((accessToken) => {
            return getUser(user.id.split(":")[2], accessToken);
          })
          .then((response) => {
            if (response.status === 200) {
              setUserInfo(response.data);
              setLoadingUserInfo(false);
            } else {
              setLoadingUserInfo(false);
              navigate("/InviteCode");
            }
          });
      }
    }
  }, [ready]);

  useEffect(() => {
    setLoadingWallet(true);
    setTimeout(() => {
      setLoadingWallet(false);
    }, 10000);
  }, []);

  useEffect(() => {
    if (
      ready &&
      authenticated &&
      userInfo &&
      !loadingWallet &&
      !embeddedWallet
    ) {
      navigate("/CreateWallet");
    }
  }, [ready, authenticated, userInfo, loadingWallet, embeddedWallet]);

  // Create a custom theme
  const theme = createTheme({
    palette: {
      background: {
        default: "#fff", // Set the default background color to white
      },
    },
  });

  // Wait until the Privy client is ready before taking any actions
  if (!ready) {
    return <></>;
  }

  if (loadingUserInfo) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "90vh",
        }}
      >
        <CircularProgress size={80} />
      </Box>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div style={{ flex: 1, marginBottom: "80px" }}>
            <Routes>
              <Route path="/Login" element={<LoginPage />} />
              <Route
                path="/InviteCode"
                element={<InviteCodePage setUserInfo={setUserInfo} />}
              />
              <Route
                path="/CreateWallet"
                element={<CreateWalletPage wallet={embeddedWallet} />}
              />
              <Route
                path="/Airdrop"
                element={
                  <AirdropPage
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                    wallet={embeddedWallet}
                  />
                }
              />
              <Route
                path="/Wallet"
                element={
                  <WalletPage
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                    wallet={embeddedWallet}
                  />
                }
              />
              <Route
                path="/Trending"
                element={<TrendingMemes userInfo={userInfo} />}
              />
              <Route
                path="/Meming"
                element={
                  <MyMemes userInfo={userInfo} wallet={embeddedWallet} />
                }
              />
              <Route path="/" element={<Feed userInfo={userInfo} />} />
              <Route path="/Notification" element={<NotificationPage />} />
            </Routes>
          </div>
        </ThemeProvider>
        {ready && authenticated && userInfo && <BottomBar />}
      </div>
    );
  }
}

export default App;
