import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import CelebrationIcon from "@mui/icons-material/Celebration";
import ErrorIcon from "@mui/icons-material/Error";
import { useNavigate } from "react-router-dom";

const CongratulationsPage = ({ isSuccess, isOpen, handleClose, memeName }) => {
  const navigate = useNavigate();

  const onSuccessClickNavigate = () => {
    handleClose();
    navigate("/Meming");
  };

  const onFailClickNavigate = () => {
    handleClose();
    navigate("/");
    window.location.reload();
  };

  // const [showSpinner, setShowSpinner] = useState(true);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowSpinner(false);
  //   }, 8000); // 8 seconds delay

  //   return () => clearTimeout(timer);
  // }, []);

  return isSuccess ? (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Box display='flex' alignItems='center' justifyContent='center' gap={1}>
          <Typography variant='h6'>Getting First Meme...</Typography>
        </Box>
      </DialogTitle>
      <DialogContent style={{ minHeight: "200px", minWidth: "300px" }}>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          style={{ width: "100%", height: "100%", marginTop: "50px" }}>
          <CircularProgress />
        </Box>
      </DialogContent>
      {/* </>
      )} */}
    </Dialog>
  ) : (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Box display='flex' alignItems='center' justifyContent='center' gap={1}>
          <ErrorIcon />
          <Typography variant='h6'>Sorry...</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant='body1' sx={{ marginBottom: "20px" }}>
          You failed to mint, perhaps someone fat fingered it first.
        </Typography>
        <Typography variant='body2'>
          Don't worry, you are probably early. Go back to home page and start
          meming!
        </Typography>
        <Box textAlign='center' marginTop='20px'>
          <Button
            onClick={onFailClickNavigate}
            variant='contained'
            color='primary'
            sx={{ borderRadius: "20px" }}>
            Go Back
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CongratulationsPage;
