import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import ChatIcon from "@mui/icons-material/Chat";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box } from "@mui/material";
import { blue } from "@mui/material/colors";
import BuySellMeme from "./BuySellMeme";
import { useNavigate } from "react-router-dom";

import ChatIconButton from "./buttons/ChatIconButton";
import { Memes } from "../util/util";
import PeopleIcon from "@mui/icons-material/People";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import Inventory2Icon from "@mui/icons-material/Inventory";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import { priceFromSupply, mcapFromSupply } from "../util/util";

// import myMemesData from "../data/newsData";

// const ExpandMore = styled((props) => {
//   const { expand, ...other } = props;
//   return <IconButton {...other} />;
// })(({ theme, expand }) => ({
//   transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
//   marginLeft: "auto",
//   transition: theme.transitions.create("transform", {
//     duration: theme.transitions.duration.shortest,
//   }),
// }));

function Stock({
  memeId,
  title,
  ticker,
  supply,
  tickerImage,
  holding,
  memeType,
}) {
  // const [expanded, setExpanded] = useState(false);

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };

  const [popupVisible, setPopupVisible] = useState(false);
  const handleMemeClick = () => {
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  return (
    <>
      <Card sx={{ maxWidth: "100%" }}>
        <CardHeader
          avatar={
            <Avatar
              sx={{ bgcolor: red[500] }}
              aria-label='ticker'
              src={tickerImage}></Avatar>
          }
          action={
            // <IconButton aria-label='settings'>
            //   <MoreVertIcon />
            // </IconButton>
            <Box
              onClick={handleMemeClick}
              sx={{
                backgroundColor: blue[500],
                color: "white",
                padding: "10px 15px", // Adjust padding as needed
                borderRadius: "50px", // Add rounded corners if desired
              }}>
              <Typography variant='body2'>Meme</Typography>
            </Box>
          }
          title={
            <Typography
              variant='h6'
              component='div'
              sx={{
                fontWeight: "bold", // Add this line to make the text bold
              }}>
              {ticker}
            </Typography>
          }
          subheader={
            <Typography variant='body2' component='div' color={"green"}>
              {priceFromSupply(supply)} Eth
            </Typography>
          }
        />
        <CardContent sx={{ marginTop: "-20px" }}>
          <Typography variant='body2' color='text.secondary'>
            {title}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            marginTop: "-20px",
            marginBottom: "-5px",
          }}
          disableSpacing>
          {/* <IconButton aria-label='add to favorites'>
            <BookmarkIcon />
          </IconButton>
          <ChatIconButton ticker={ticker} /> */}
          {memeType === Memes.TRENDING && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                // justifyContent: "right",
                width: "100%",
              }}>
              <IconButton aria-label='add to favorites'>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                  }}>
                  <CorporateFareIcon fontSize='small' />
                  <Typography variant='body2' sx={{ marginLeft: "auto" }}>
                    MCap: {mcapFromSupply(supply)} Eth
                  </Typography>
                </Box>
              </IconButton>
              <IconButton aria-label='Unique Holders'>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                  }}>
                  <PeopleIcon fontSize='small' />
                  <Typography variant='body2' sx={{ marginLeft: "auto" }}>
                    Supply: {supply}
                    {/* Memers: {holding} */}
                  </Typography>
                </Box>
              </IconButton>
            </Box>
          )}
          {memeType === Memes.MEMING && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                // justifyContent: "right",
                width: "100%",
              }}>
              <IconButton aria-label='add to favorites'>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                  }}>
                  <CorporateFareIcon fontSize='small' />
                  <Typography variant='body2' sx={{ marginLeft: "auto" }}>
                    MCap: {mcapFromSupply(supply)} Eth
                  </Typography>
                </Box>
              </IconButton>
              <IconButton aria-label='My Holdings'>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                  }}>
                  <SentimentSatisfiedAltIcon fontSize='small' />
                  <Typography variant='body2' sx={{ marginLeft: "auto" }}>
                    Meming: {holding}
                  </Typography>
                </Box>
              </IconButton>
            </Box>

            // <Box
            //   sx={{
            //     display: "flex",
            //     flexDirection: "row",
            //     gap: "5px",
            //   }}>
            //   <PeopleIcon />
            //   <Box
            //     sx={{
            //       marginTop: "2px",
            //     }}>
            //     {holding}
            //   </Box>
            // </Box>
            // <Typography variant='body2' sx={{ marginLeft: "auto" }}>
            //   Meming: {holding}
            // </Typography>
          )}
          {/* <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label='show more'>
            <ExpandMoreIcon />
          </ExpandMore> */}
        </CardActions>
        {/* <Collapse in={expanded} timeout='auto' unmountOnExit>
          <CardContent>
            <Typography paragraph>Method:</Typography>
            <Typography paragraph>
              Heat 1/2 cup of the broth in a pot until simmering, add saffron
              and set aside for 10 minutes.
            </Typography>
            <Typography>
              Set aside off of the heat to let rest for 10 minutes, and then
              serve.
            </Typography>
          </CardContent>
        </Collapse> */}
      </Card>
      <BuySellMeme
        memeId={memeId}
        isOpen={popupVisible}
        handleClose={handleClosePopup}
        ticker={ticker}
        tickerImage={tickerImage}
        news={title}
      />
      {/* {showMemeToJoinChatAlert && <MemeToJoinChatAlert title={ticker} />} */}
    </>
  );
}

export default Stock;
