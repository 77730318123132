const { Web3 } = require("web3");
// const web3 = new Web3(
//   new Web3.providers.HttpProvider("https://rpc.ankr.com/eth_goerli")
// );
// const blockchain = "eth_goerli";
// const chainId = "5";
// // Need to change this to env variable!!!
// const projectId =
//   "5ca22e4bdb14be22cc54b1c353d39fa1134650ca16cff72c53bec403ba329bbd";
// const web3 = new Web3(
//   new Web3.providers.HttpProvider(
//     `https://rpc.ankr.com/${blockchain}/${projectId}`
//   )
// );

const blastBlockChain = "blast_testnet_sepolia";
const blastProjectId =
  "9438d2f1e95256af55f14df2ca03cbd970756d746e40cb4683e40dca759ae560";
const httpUrl = `https://rpc.ankr.com/${blastBlockChain}/${blastProjectId}`;
const web3 = new Web3(new Web3.providers.HttpProvider(httpUrl));
const chainId = 168587773;

const wsUrl = `wss://rpc.ankr.com/${blastBlockChain}/ws/${blastProjectId}`;
// const WebSocket = require("ws");
// const ws = new WebSocket(wsUrl);

const contractABI = [
  {
    inputs: [
      {
        internalType: "string",
        name: "memeId",
        type: "string",
      },
    ],
    name: "addMeme",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "memeId",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "buyMemes",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "minter",
        type: "address",
      },
      {
        indexed: false,
        internalType: "string",
        name: "memeId",
        type: "string",
      },
      {
        indexed: false,
        internalType: "address",
        name: "meme",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "supply",
        type: "uint256",
      },
    ],
    name: "Mint",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "memeId",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "sellMemes",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_bountyDestination",
        type: "address",
      },
    ],
    name: "setBountyDestination",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_bountyPercent",
        type: "uint256",
      },
    ],
    name: "setBountyPercent",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_feeDestination",
        type: "address",
      },
    ],
    name: "setProtocolFeeDestination",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "trader",
        type: "address",
      },
      {
        indexed: false,
        internalType: "string",
        name: "memeId",
        type: "string",
      },
      {
        indexed: false,
        internalType: "address",
        name: "meme",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "isBuy",
        type: "bool",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "memeAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "ethAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "protocolEthAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "bountyEthAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "supply",
        type: "uint256",
      },
    ],
    name: "Trade",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "bountyDestination",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "bountyPercent",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "memeId",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "getBuyPrice",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "supply",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "getPrice",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "memeId",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "getSellPrice",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    name: "memes",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "memesBalance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "memesSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "protocolFeeDestination",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "sellGap",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

// const contractAddress = "0xDeC5A37ED891B8a360f39Ce8A07A1bFE92EaC02b";
// const contractAddress = "0x4F3b4484C4febdEf548d0D6e000a01fac03a8316";
// const contractAddress = "0x39967c281d9E7132C3E88FB4Cf7d60a2DB02e472";
// const contractAddress = "0x3a8FCB934450730ca99B23178Be9aF0203398CBe";
// const contractAddress = "0x384D369665C065181A25c8517c0A0b6Fb42C16F8";
// const contractAddress = "0x29595B398Dc5EB16CC2dd4C0c5F1b1f2C12fBB88"; // blast!!
const contractAddress = "0x6aCC630485961C8b4ae9613c1564903C5aF8bA86";

const contract = new web3.eth.Contract(contractABI, contractAddress);
const nullAddress = "0x0000000000000000000000000000000000000000";

const getMemeSupplyFromMemeId = async (memeId) => {
  // 8FKK7NNt4AXWMLGw2sOk
  return contract.methods
    .memes(memeId)
    .call()
    .then(async (memeAddress) => {
      // console.log("Address associated with meme ID: ", memeAddress);
      // may need to proces buy price
      const supply = await getMemeSupplyFromMemeAddress(memeAddress);
      return supply;
    })
    .catch((error) => {
      console.error("Error fetching address: ", error);
      return error;
    });
};

const getMemeSupplyFromMemeAddress = async (address) => {
  if (address === nullAddress) {
    return 0;
  }
  return contract.methods
    .memesSupply(address)
    .call()
    .then((result) => {
      // console.log("Address associated with meme ID: ", result);
      return result;
    })
    .catch((error) => {
      console.error("Error fetching address: ", error);
    });
};

const getMemeBalanceFromMemeId = async (memeId, walletAddress) => {
  // !!!should change this into one call, add function to contract!!!
  // console.log("walletAddress: ", walletAddress);
  return contract.methods
    .memes(memeId)
    .call()
    .then(async (memeAddress) => {
      console.log("Address associated with meme ID: ", memeAddress);
      const balance = await getMemeBalanceFromMemeAddress(
        memeAddress,
        walletAddress
      );
      return balance;
    })
    .catch((error) => {
      console.error("Error getMemeBalanceFromMemeId: ", error);
    });
};

const getMemeBalanceFromMemeAddress = async (address, walletAddress) => {
  if (address === nullAddress) {
    return 0;
  }

  return contract.methods
    .memesBalance(address, walletAddress)
    .call()
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.error("Error getMemeBalanceFromMemeAddress: ", error);
    });
};

const getMemeBuyPrice = async (memeId, amount) => {
  return contract.methods
    .getBuyPrice(memeId, amount)
    .call()
    .then((result) => {
      // console.log("Address associated with meme ID: ", result);
      // may need to proces buy price
      return result;
    })
    .catch((error) => {
      console.error("Error getting buy price: ", error);
    });
};

const getMemeSellPrice = async (memeId, amount) => {
  return contract.methods
    .getSellPrice(memeId, amount)
    .call()
    .then((result) => {
      // console.log("Address associated with meme ID: ", result);
      // may need to proces buy price
      return result;
    })
    .catch((error) => {
      console.error("Error getting sell price: ", error);
    });
};

const getCurrentGasPrice = async () => {
  const crGasPrice = await web3.eth.getGasPrice();
  return crGasPrice;
};

const getCurrentNonce = async (accountAddress) => {
  const currentNonce = await web3.eth.getTransactionCount(
    accountAddress,
    "latest"
  );
  // console.log("currentNonce: ", currentNonce);
  return currentNonce;
};

const getAddMemeTx = async ({ memeId, sender }) => {
  // need to fix price!!!
  const addMemeTx = (memeId, nonce, gasPrice, sender) => {
    const data = contract.methods.addMeme(memeId);
    const unsignedTx = {
      to: contractAddress,
      data: data.encodeABI(),
      gasPrice: gasPrice, // Estimate or set the gas limit
      from: sender,
      nonce: web3.utils.toHex(nonce),
    };
    return unsignedTx;
  };
  const currentNonce = await getCurrentNonce(sender);
  const currentGasPrice = await getCurrentGasPrice();
  return addMemeTx(memeId, currentNonce, currentGasPrice, sender);
};

const getBuyMemeTx = async ({ memeId, amount, sender, price }) => {
  // need to fix price!!!
  const buyMemeTx = (memeId, amount, nonce, gasPrice, sender, price) => {
    const data = contract.methods.buyMemes(memeId, amount);
    const unsignedTx = {
      to: contractAddress,
      data: data.encodeABI(),
      gasPrice: gasPrice, // Estimate or set the gas limit
      from: sender,
      nonce: web3.utils.toHex(nonce),
      value: price,
    };
    return unsignedTx;
  };
  console.log("sender: ", sender);
  const currentNonce = await getCurrentNonce(sender);
  const currentGasPrice = await getCurrentGasPrice();
  console.log("currentNonce: ", currentNonce);
  console.log();
  return buyMemeTx(
    memeId,
    amount,
    currentNonce,
    currentGasPrice,
    sender,
    price
  );
};

const getSellMemeTx = async ({ memeId, amount, sender }) => {
  const sellMemeTx = (memeId, amount, nonce, gasPrice, sender) => {
    // console.log("pk: ", pk);
    const data = contract.methods.sellMemes(memeId, amount);
    const unsignedTx = {
      to: contractAddress,
      data: data.encodeABI(),
      gasPrice: gasPrice, // Estimate or set the gas limit
      from: sender,
      nonce: web3.utils.toHex(nonce),
    };
    return unsignedTx;
  };
  const currentNonce = await getCurrentNonce(sender);
  const currentGasPrice = await getCurrentGasPrice();
  // console.log("currentNonce: ", currentNonce);
  return sellMemeTx(memeId, amount, currentNonce, currentGasPrice, sender);
};

export {
  getMemeSupplyFromMemeId,
  getMemeBalanceFromMemeId,
  getMemeBuyPrice,
  getMemeSellPrice,
  getAddMemeTx,
  getBuyMemeTx,
  getSellMemeTx,
  chainId,
  httpUrl,
  wsUrl,
};

// const main = async () => {
//   const memeId = "ntquwWgzbsAhKKyseMAM";
//   const price = await getMemeSellPrice(memeId, 1);
//   console.log("price: ", price);
// };

// main();
