const registerServiceWorker = async () => {
  try {
    if ("serviceWorker" in navigator) {
      const registration = await navigator.serviceWorker.register(
        "/service-worker.js",
        { scope: "/" }
      );
      console.log("Service worker registered with scope: ", registration.scope);
    } else {
      console.log("Service worker not available in navigator");
    }
  } catch (error) {
    console.error(`Registration failed with ${error}`);
  }
};

export { registerServiceWorker };
