import React, { useEffect } from "react";
import { usePrivy } from "@privy-io/react-auth";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/base/Button";
import { styled } from "@mui/system";
import { blue } from "@mui/material/colors";
import Grid from "@mui/material/Grid";

function isPWA() {
  return (
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator.standalone || // For iOS Safari
    document.referrer.startsWith("android-app://")
  );
}

const LoginPage = () => {
  const navigate = useNavigate();
  const { ready, authenticated, login } = usePrivy();

  useEffect(() => {
    if (ready && authenticated) {
      navigate("/InviteCode");
    }
  });

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      style={{ height: "100vh", marginTop: "150px" }}
    >
      <Grid item padding={"20px"}>
        <StyledH2> Welcome to Meme News! </StyledH2>
        {isPWA() ? (
          <StyledButton onClick={login}> Log in or Sign up </StyledButton>
        ) : (
          <>
            <StyledH4>Available to use as an app.</StyledH4>
            <StyledH4>
              1. Click share at the bottom of your phone screen (need to scroll
              down a little after clicking share).
            </StyledH4>
            <StyledH4>2. Tap "Add to Home Screen".</StyledH4>
            <StyledH4>
              3. On your home screen, you should now see an app "Meme News". App
              icon is a dog with a hat holding newspapers. Tap on the app to
              start your meme journey.
            </StyledH4>
          </>
        )}
        {/* <StyledButton onClick={login}> Log in or Sign up </StyledButton> */}
      </Grid>
    </Grid>
  );
};

export default LoginPage;

const StyledH2 = styled("h2")({
  fontSize: "24px",
  color: blue[500],
});

const StyledH4 = styled("h4")({
  fontSize: "12px",
  color: "grey",
});

const StyledButton = styled(Button)({
  width: "100%",
  background: blue[600],
  padding: "20px",
  marginTop: "40px",
  marginBottom: "40px",
  borderRadius: 60,
  border: "none",
  fontSize: "20px",
  color: "white",
  "&:hover": { background: blue[800] },
});
