import React, { forwardRef, useEffect, useState } from "react";
import "./Post.css";
import {
  Container,
  Typography,
  Divider,
  Paper,
  Grid,
  Avatar,
  IconButton,
  Box,
} from "@mui/material";
import { blue, green } from "@mui/material/colors";
import BuySellMeme from "./BuySellMeme";
import { timeSince } from "../util/util";

import {
  VerifiedUser as VerifiedUserIcon,
  // ChatBubbleOutline as ChatBubbleOutlineIcon,
  // Repeat as RepeatIcon,
  // FavoriteBorder as FavoriteBorderIcon,
  Publish as PublishIcon,
} from "@mui/icons-material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ChatIcon from "@mui/icons-material/Chat";
import ShareIcon from "@mui/icons-material/Share";
import GroupIcon from "@mui/icons-material/Group";
import { mediaToImgUrl } from "../util/mediaImgs";
import MemeIt from "./MemeIt";
import {
  getMemeSupplyFromMemeId,
  getMemeBalanceFromMemeId,
} from "../util/contracts/memeFunctions";

const Post = forwardRef(
  (
    {
      memeId,
      articleId,
      displayName,
      summary,
      title,
      tickerImage,
      time,
      ticker,
      isOnchain,
    },
    ref
  ) => {
    const [popupVisible, setPopupVisible] = useState(false);
    const handleMemeClick = () => {
      setPopupVisible(true);
    };

    const handleClosePopup = () => {
      setPopupVisible(false);
    };

    const [isExpanded, setIsExpanded] = useState(false);
    const toggleShowMore = () => {
      setIsExpanded(!isExpanded);
    };

    // need to fetch this from blockchain
    const [isMemed, setIsMemed] = useState(
      // ticker !== null && ticker !== undefined
      isOnchain
    );

    // const [supply, setSupply] = useState(0);
    // useEffect(() => {
    //   if (popupVisible) {
    //     getMemeSupplyFromMemeId(memeId).then((result) => {
    //       const supplyNum = Number(result);
    //       console.log("meme supply: ", supplyNum);
    //       setSupply(supplyNum);
    //       setIsMemed(supplyNum > 0);
    //     });
    //   }
    // }, [memeId, popupVisible]);

    // const [balance, setBalance] = useState(0);
    // useEffect(() => {
    //   if (popupVisible) {
    //     // will need to fetch this from user wallet
    //     let walletAddress = "0xa6C19aDb4Cba619a637954c739eaF708e5404055";
    //     getMemeBalanceFromMemeId(memeId, walletAddress).then((result) => {
    //       const balanceNum = Number(result);
    //       console.log("meme supply: ", balanceNum);
    //       setBalance(balanceNum);
    //     });
    //   }
    // }, [memeId, popupVisible]);

    // const [buyPrice, setBuyPrice] = useState(0);
    // useEffect(() => {
    //   if (popupVisible) {
    //     // will need to fetch this from user wallet
    //     let walletAddress = "0xa6C19aDb4Cba619a637954c739eaF708e5404055";
    //     getMemeBalanceFromMemeId(memeId, walletAddress).then((result) => {
    //       const balanceNum = Number(result);
    //       console.log("meme supply: ", balanceNum);
    //       setBalance(balanceNum);
    //     });
    //   }
    // }, [memeId, popupVisible]);

    // const [sellPrice, setSellPrice] = useState(0);
    // useEffect(() => {
    //   if (popupVisible) {
    //     // will need to fetch this from user wallet
    //     let walletAddress = "0xa6C19aDb4Cba619a637954c739eaF708e5404055";
    //     getMemeBalanceFromMemeId(memeId, walletAddress).then((result) => {
    //       const balanceNum = Number(result);
    //       console.log("meme supply: ", balanceNum);
    //       setBalance(balanceNum);
    //     });
    //   }
    // }, [memeId, popupVisible]);

    const shareOnTwitter = () => {
      const message = "I just memed"; // Replace with your message
      const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        message
      )}`;
      const twitterWindow = window.open(
        url,
        "twitterShare",
        "width=550,height=235"
      );

      const interval = setInterval(() => {
        if (twitterWindow.closed) {
          clearInterval(interval);
          // Handle the twitter window closure here
          console.log("Twitter share window closed");
        }
      }, 500);
    };

    return (
      <>
        <div className='post' ref={ref}>
          <div className='post__avatar'>
            <Avatar src={mediaToImgUrl[displayName.toLowerCase()]} />
          </div>
          <div className='post__body'>
            <div className='post__header'>
              <div className='post__headerLeft'>
                <div className='post__headerText'>
                  <h3>
                    {displayName}{" "}
                    <span className='post__headerSpecial'>
                      {/* {verified && <VerifiedUserIcon className='post__badge' />} @ */}
                      {`- ${timeSince(time)}`}
                    </span>
                  </h3>
                </div>
              </div>
              <div className='post__headerRight'>
                {isMemed ? (
                  <Box
                    onClick={handleMemeClick}
                    sx={{
                      backgroundColor: blue[500],
                      color: "white",
                      padding: "10px 15px", // Adjust padding as needed
                      borderRadius: "50px", // Add rounded corners if desired
                    }}>
                    <Typography variant='body2'>{`Meme`}</Typography>
                  </Box>
                ) : (
                  <Box
                    onClick={handleMemeClick}
                    sx={{
                      backgroundColor: green[500],
                      color: "white",
                      padding: "10px 15px", // Adjust padding as needed
                      borderRadius: "50px", // Add rounded corners if desired
                    }}>
                    <Typography variant='body2'>{`Meme it`}</Typography>
                  </Box>
                )}
              </div>
            </div>
            <div className='post__bodyContent'>
              <p>{ticker && `TICKER - ${ticker}`}</p>
              <p>
                {title}
                {!isExpanded && "..."}
                {!isExpanded && (
                  <button onClick={toggleShowMore} className='show-more-button'>
                    More
                  </button>
                )}
              </p>
              {/* {!isExpanded && (
                <div className='show-more-container'>
                  <p> </p>
                  <button onClick={toggleShowMore} className='show-more-button'>
                    Show More
                  </button>
                </div>
              )} */}
              <p>
                {isExpanded && summary}{" "}
                {isExpanded && (
                  <button onClick={toggleShowMore} className='show-more-button'>
                    Less
                  </button>
                )}
              </p>
            </div>
            <img src={tickerImage} alt='' />
            <div
              className='post__footer'
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}>
              {/* <IconButton aria-label='like' sx={{ gap: "2px" }}>
                <FavoriteBorderIcon fontSize='small' />
                <Typography variant='body2'>238</Typography>
              </IconButton>
              <IconButton aria-label='holders' sx={{ gap: "2px" }}>
                <GroupIcon fontSize='small' />
                <Typography variant='body2'>17</Typography>
              </IconButton> */}
              {/* <IconButton
                aria-label='share'
                sx={{ gap: "2px" }}
                onClick={shareOnTwitter}>
                <Typography variant='body2'> Share to earn</Typography>
                <ShareIcon fontSize='small' />
              </IconButton> */}
              {/* <IconButton aria-label='like'>
                <FavoriteBorderIcon fontSize='small' />
                <Typography variant='body2'>238</Typography>
              </IconButton>
              <IconButton aria-label='go to chats'>
                <ChatBubbleOutlineIcon fontSize='small' />
                <Typography variant='body2'>10</Typography>
              </IconButton>
              <IconButton aria-label='bookmark to watchlist'>
                <BookmarkBorderIcon />
              </IconButton>{" "}
              */}
              {/* <PublishIcon fontSize='small' /> */}
            </div>
          </div>
          {/* Meme Popup */}
          {/* {popupVisible && (
          <div className='popup'>
            <div className='popup__content'>
              <h2>{`Meme - ${ticker}`}</h2>
              <p>A new AI tool, Gemini, has just been released by Google.</p>
              <div className='popup__buttons'>
                <button className='popup__buy-button'>
                  Buy a key
                  <span>Price is 0.02 ETH</span>
                </button>
                <button className='popup__sell-button'>
                  Sell a key
                  <span>Price is 0.01 ETH</span>
                </button>
              </div>
              <button
                className='popup__close-button'
                onClick={handleClosePopup}>
                Close
              </button>
            </div>
          </div>
        )} */}
        </div>
        {isMemed ? (
          <BuySellMeme
            memeId={memeId}
            isOpen={popupVisible}
            handleClose={handleClosePopup}
            ticker={ticker}
            tickerImage={tickerImage}
            news={title}
            setIsMemed={setIsMemed}
          />
        ) : (
          <MemeIt
            memeId={memeId}
            articleId={articleId}
            isOpen={popupVisible}
            handleClose={handleClosePopup}
            ticker={ticker}
            tickerImage={tickerImage}
            news={title}
            setIsMemed={setIsMemed}
          />
        )}
      </>
    );
  }
);

export default Post;
