import React, { useEffect } from "react";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import HomeIcon from "@mui/icons-material/Home";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ParaglidingIcon from "@mui/icons-material/Paragliding";
import { useNavigate, useLocation } from "react-router-dom"; // Use useNavigate instead of useHistory

function BottomBar() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate(); // Get the navigate function
  const location = useLocation();

  const handleNavigationChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      navigate("/");
    } else if (newValue === 1) {
      navigate("/Trending");
    } else if (newValue === 2) {
      navigate("/Meming");
    } else if (newValue === 3) {
      navigate("/Airdrop");
    }
  };

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname === "/") {
      setValue(0);
    } else if (pathname === "/Trending") {
      setValue(1);
    } else if (pathname === "/Meming") {
      setValue(2);
    } else if (pathname === "/Airdrop") {
      setValue(3);
    }
  }, [location.pathname]);

  const iconStyle = {
    fontSize: "1.5rem", // Define your font size here
    // You can add more style properties if needed
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
      }}
    >
      <BottomNavigation
        style={{
          height: "80px", // Increase the height
          padding: "0 0 20px", // Add padding at the top and bottom
        }}
        value={value}
        onChange={handleNavigationChange}
        showLabels
      >
        <BottomNavigationAction
          label="Home"
          icon={<HomeIcon style={iconStyle} />}
        />
        <BottomNavigationAction
          label="Trending"
          icon={<ShowChartIcon style={iconStyle} />}
        />
        <BottomNavigationAction
          label="Meming"
          icon={<AccountBalanceWalletIcon style={iconStyle} />}
        />
        <BottomNavigationAction
          label="Airdrop"
          icon={<ParaglidingIcon style={iconStyle} />}
        />
      </BottomNavigation>
    </div>
  );
}

export default BottomBar;
