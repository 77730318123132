import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Container,
  Avatar,
  Grid,
  Box,
  Divider,
  CircularProgress,
  IconButton,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import TwitterIcon from "@mui/icons-material/Twitter";
import { blue } from "@mui/material/colors";
import AppHeader from "./AppHeader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Zoom from "@mui/material/Zoom";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import InventoryIcon from "@mui/icons-material/Inventory";
import { styled } from "@mui/system";
import { getInviteCode, updateUser } from "../util/backendApi";
import { usePrivy } from "@privy-io/react-auth";
import { authentication } from "../firebase-config";
import { TwitterAuthProvider, signInWithPopup } from "firebase/auth";
import { useBalance } from "wagmi";
import { chainId } from "../util/contracts/memeFunctions";
import { getUserPortfolioValue } from "../util/backendApi";

const AirdropPage = ({ userInfo, setUserInfo, wallet }) => {
  const navigate = useNavigate();
  const [codes, setCodes] = useState([]);
  const [loadingCodes, setLoadingCodes] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const { user, getAccessToken } = usePrivy();

  const [airdropAmount, setAidropAmount] = useState(0);
  // !!! need to find out setairdropamount !!!

  const { data, isError, isLoading } = useBalance({
    address: wallet?.address,
    chainId: chainId,
  });

  const [airdropOpen, setAirDropOpen] = useState(false);
  const handleAirdropClose = () => {
    setAirDropOpen(false);
  };
  const handleAirdropOpen = () => {
    setAirDropOpen(true);
  };

  const [scoreOpen, setScoreOpen] = useState(false);
  const handleScoreClose = () => {
    setScoreOpen(false);
  };
  const handleScoreOpen = () => {
    setScoreOpen(true);
  };

  const [portfolioValue, setPortfolioValue] = useState(0);

  useEffect(() => {
    // will need to fetch this from database
    const address = wallet?.address;
    getAccessToken()
      .then((accessToken) => {
        return getUserPortfolioValue(address, accessToken);
      })
      .then((result) => {
        setPortfolioValue(result.data);
      })
      .catch((err) => console.log("Error retrieving portfolio value", err));
  }, [wallet?.address]);

  const signInWithTwitter = () => {
    const provider = new TwitterAuthProvider();
    signInWithPopup(authentication, provider)
      .then(async (res) => {
        const twitterUser = res._tokenResponse;
        const updatedFields = {
          twitterInfo: {
            photoUrl: twitterUser.photoUrl.replace("_normal", ""),
            displayName: twitterUser.displayName,
            screenName: twitterUser.screenName,
          },
        };
        const accessToken = await getAccessToken();
        const response = await updateUser(
          user.id.split(":")[2],
          updatedFields,
          accessToken
        );
        setUserInfo(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const CustomTransition = (props) => {
    return <Zoom {...props} timeout={{ enter: 250, exit: 250 }} />;
  };

  const handleCopy = (code) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard
        .writeText(code)
        .then(() => {
          setAlertMessage(`Copied invite code ${code} to your clipboard!`);
          setSnackbarOpen(true);
        })
        .catch((error) => {
          console.error(error);
          setAlertMessage("Failed to copy invite code.");
          setSnackbarOpen(true);
        });
    } else {
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement("textarea");
      textArea.value = code;

      // Move textarea out of the viewport so it's not visible
      textArea.style.position = "absolute";
      textArea.style.left = "-999999px";

      document.body.prepend(textArea);
      textArea.select();

      try {
        document.execCommand("copy");
        setAlertMessage(`Copied invite code ${code} to your clipboard!`);
        setSnackbarOpen(true);
      } catch (error) {
        console.error(error);
        setAlertMessage("Failed to copy invite code.");
        setSnackbarOpen(true);
      } finally {
        textArea.remove();
      }
    }
  };

  const handleSnackbarClose = () => {
    setAlertMessage("");
    setSnackbarOpen(false);
  };

  const shareOnTwitter = () => {
    const message = "Join me on MemeNews now!"; // Replace with your message
    const tweetUrl = "https://memenews.xyz";
    const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      message
    )}&url=${encodeURIComponent(tweetUrl)}`;
    const twitterWindow = window.open(
      url,
      "twitterShare",
      "width=550,height=235"
    );

    const interval = setInterval(() => {
      if (twitterWindow.closed) {
        clearInterval(interval);
        // Handle the twitter window closure here
        console.log("Twitter share window closed");
      }
    }, 500);
  };

  const canShareData = () => {
    if (!navigator.share || !navigator.canShare) {
      return false;
    }
    const data = { text: "text", url: "https://example.com" };
    return navigator.canShare(data);
  };

  const sharePwa = () => {
    navigator
      .share({
        text: "Join me on MemeNews now!",
        url: "https://memenews.xyz",
      })
      .then(() => {
        console.log("The message was successfully shared");
      })
      .catch((err) => {
        console.error(`The message could not be shared: ${err}`);
      });
  };

  useEffect(() => {
    setLoadingCodes(true);
    const fetchCodes = async () => {
      const fetchedCodes = await Promise.all(
        userInfo.inviteCodes.map(async (code) => {
          const accessToken = await getAccessToken();
          const response = await getInviteCode(code, accessToken);
          return { inviteCode: code, isUsed: response.data.isUsed };
        })
      );
      setCodes(fetchedCodes);
      setLoadingCodes(false);
    };
    fetchCodes();
  }, [userInfo.inviteCodes]);

  return (
    <>
      <AppHeader appBarTitle={"Airdrop"} userInfo={userInfo} />
      <Container sx={{ marginTop: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                paddingLeft: 2,
                paddingRight: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50vw",
                  overflowX: "hidden",
                }}>
                <Avatar
                  sx={{ width: 100, height: 100 }}
                  src={
                    userInfo.twitterInfo ? userInfo.twitterInfo.photoUrl : ""
                  }
                  alt='Profile Picture'
                />
                <Box sx={{ marginTop: 1 }}>
                  {userInfo.twitterInfo && (
                    <Typography variant='h6'>
                      {userInfo.twitterInfo.displayName}
                    </Typography>
                  )}
                  {userInfo.twitterInfo && (
                    <Typography variant='body2'>
                      @{userInfo.twitterInfo.screenName}
                    </Typography>
                  )}
                  {!userInfo.twitterInfo && (
                    <StyledButton onClick={signInWithTwitter}>
                      Link Twitter
                    </StyledButton>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                  }}>
                  <IconButton
                    sx={{
                      paddingTop: 1,
                      paddingLeft: 0,
                      paddingBottom: 0,
                      paddingRight: 0,
                    }}
                    onClick={shareOnTwitter}>
                    <TwitterIcon fontSize='small' />
                  </IconButton>
                  {canShareData() && (
                    <IconButton
                      sx={{
                        paddingTop: 1,
                        paddingLeft: 2,
                        paddingBottom: 0,
                        paddingRight: 0,
                      }}
                      onClick={sharePwa}>
                      <ShareIcon fontSize='small' />
                    </IconButton>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  alignItems: "right",
                  width: "50vw",
                }}>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}>
                  <Typography variant='body1'>Wallet Balance</Typography>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}>
                    <FontAwesomeIcon
                      icon={faEthereum}
                      style={{ color: "#000000", fontSize: "16px" }}
                    />
                    <Typography variant='h6' sx={{ marginLeft: "5px" }}>
                      {/* {Number(data?.formatted).toFixed(2)} {data?.symbol} */}
                      {Number(data?.formatted).toFixed(3)}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}>
                  <Typography variant='body1'>Portfolio Value</Typography>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}>
                    <InventoryIcon sx={{ fontSize: "16px" }} />
                    <Typography variant='h6' sx={{ marginLeft: "3px" }}>
                      {portfolioValue && portfolioValue.toFixed(3)}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}>
                  <StyledButton
                    onClick={() => {
                      navigate("/Wallet");
                    }}>
                    Deposit & Withdraw
                  </StyledButton>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Divider variant='middle' />
            <Box
              sx={{
                paddingTop: 2,
                paddingLeft: 2,
                paddingRight: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}>
                  {/* <Typography variant='body1' style={{ marginRight: "5px" }}>
                    News Airdrop
                  </Typography> */}
                  {/* <ClickAwayListener onClickAway={handleAirdropClose}>
                    <Tooltip
                      arrow
                      disableFocusListener
                      disableTouchListener
                      disableHoverListener
                      onClose={handleAirdropClose}
                      open={airdropOpen}
                      placement='right'
                      title={
                        <Typography variant='caption'>
                          airdrop tooltip
                        </Typography>
                      }>
                      <InfoIcon
                        sx={{ fontSize: "18px" }}
                        onClick={handleAirdropOpen}
                      />
                    </Tooltip>
                  </ClickAwayListener> */}
                </Box>
                {/* <Typography variant='body2'>
                  Based on your current news-memes holdings, should a
                  similar/updated news item surface in the future, you will
                  qualify for an airdrop bounty of up to 100 Eth.{" "}
                </Typography> */}
                {/* <Typography variant='h4'>{airdropAmount}</Typography> */}
              </Box>
              <Box
                sx={{
                  // marginTop: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}>
                  <Typography variant='body1' style={{ marginRight: "5px" }}>
                    Meme Score
                  </Typography>
                  {/* <ClickAwayListener onClickAway={handleScoreClose}>
                    <Tooltip
                      arrow
                      disableFocusListener
                      disableTouchListener
                      disableHoverListener
                      onClose={handleScoreClose}
                      open={scoreOpen}
                      placement='bottom'
                      title={
                        <Typography variant='caption'>
                          meme score tooltip
                        </Typography>
                      }>
                      <InfoIcon
                        sx={{ fontSize: "18px" }}
                        onClick={handleScoreOpen}
                      />
                    </Tooltip>
                  </ClickAwayListener> */}
                </Box>
                <Typography variant='body2'>
                  Score will increase with your meme trading activities.{" "}
                </Typography>
                <Typography variant='h4'>{userInfo.memeScore}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Divider variant='middle' />
            <Box
              sx={{
                paddingTop: 2,
                paddingLeft: 2,
                paddingRight: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}>
              <Typography variant='body1'>Invite Codes</Typography>
              {loadingCodes ? (
                <Box
                  sx={{
                    display: "flex",
                    marginTop: 4,
                    justifyContent: "center",
                  }}>
                  <CircularProgress size={60} />
                </Box>
              ) : (
                <List>
                  {codes.map((code, index) => (
                    <ListItem key={index}>
                      <ListItemText>
                        <Typography
                          variant='body2'
                          style={{
                            textDecoration: code.isUsed
                              ? "line-through"
                              : "none",
                          }}>
                          {code.inviteCode}
                        </Typography>
                      </ListItemText>
                      <Button
                        onClick={() => handleCopy(code.inviteCode)}
                        startIcon={<ContentCopyIcon />}
                      />
                    </ListItem>
                  ))}
                </List>
              )}
              <Snackbar
                key={alertMessage}
                open={isSnackbarOpen}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={1500}
                onClose={handleSnackbarClose}
                TransitionComponent={CustomTransition}>
                <Alert
                  variant='outlined'
                  severity={
                    alertMessage.startsWith("Failed") ? "error" : "success"
                  }
                  sx={{ bgcolor: "background.paper" }}>
                  {alertMessage}
                </Alert>
              </Snackbar>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AirdropPage;

const StyledButton = styled(Button)({
  width: "100%",
  background: blue[600],
  borderRadius: 60,
  border: "none",
  fontSize: "10px",
  color: "white",
  "&:hover": { background: blue[800] },
});
