import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Button,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import { blue } from "@mui/material/colors";
import { usePrivy } from "@privy-io/react-auth";
import { chainId } from "../util/contracts/memeFunctions";

const Withdraw = ({ isOpen, handleClose, wallet, balance }) => {
  const { sendTransaction } = usePrivy();
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState(undefined);
  const [hasError, setHasError] = useState(false);

  const handleTransaction = () => {
    const sender = wallet?.address;
    const unsignedTx = {
      to: address,
      from: sender,
      value: amount, // TODO: check this, privy says this should be in wei
      chainId: chainId,
    };
    const uiConfig = {
      header: "Transfer ETH",
      description: "Transfer to another wallet on Goerli",
      buttonText: "Complete Transfer",
    };
    sendTransaction(unsignedTx, uiConfig)
      .then((txReceipt) => {
        console.log(txReceipt);
        handleClose();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleAmountChange = (event) => {
    if (
      event.target.value < 0 ||
      event.target.value > Number(balance?.formatted)
    ) {
      setHasError(true);
    } else {
      setHasError(false);
    }
    setAmount(event.target.value);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="title">Withdraw ETH</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ marginBottom: "20px" }}>
          Transfer ETH from your meme.news wallet to another wallet on Goerli.
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection="column"
          gap="20px"
        >
          <StyledTextField
            variant="outlined"
            autoComplete="off"
            label="Enter Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            size="small"
          />
          <StyledTextField
            type="number"
            variant="outlined"
            autoComplete="off"
            label="Enter Amount"
            value={amount}
            onChange={handleAmountChange}
            error={hasError}
            helperText={hasError && "Invalid amount"}
            size="small"
          />
        </Box>
        <Typography variant="caption" sx={{ marginLeft: "15px" }}>
          Your wallet balance: {Number(balance?.formatted).toFixed(2)}{" "}
          {balance?.symbol}
        </Typography>
        <StyledButton onClick={handleTransaction}> Transfer Now </StyledButton>
      </DialogContent>
    </Dialog>
  );
};

export default Withdraw;

const StyledTextField = styled(TextField)({
  fieldset: {
    borderRadius: 40,
  },
  width: "100%",
});

const StyledButton = styled(Button)({
  width: "100%",
  background: blue[600],
  padding: "10px",
  marginTop: "20px",
  marginBottom: "20px",
  borderRadius: 40,
  border: "none",
  fontSize: "15px",
  color: "white",
  "&:hover": { background: blue[800] },
});
