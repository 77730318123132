import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  Avatar,
  Grid,
  Box,
  Divider,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import AppHeader from "./AppHeader";
import Deposit from "./Deposit";
import Withdraw from "./Withdraw";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Zoom from "@mui/material/Zoom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import InventoryIcon from "@mui/icons-material/Inventory";
import { styled } from "@mui/system";
import { updateUser } from "../util/backendApi";
import { usePrivy } from "@privy-io/react-auth";
import { useLogout } from "@privy-io/react-auth";
import { authentication } from "../firebase-config";
import { TwitterAuthProvider, signInWithPopup } from "firebase/auth";
import { useBalance } from "wagmi";
import { chainId } from "../util/contracts/memeFunctions";
import { useNavigate } from "react-router-dom";
import { getUserPortfolioValue } from "../util/backendApi";

const WalletPage = ({ userInfo, setUserInfo, wallet }) => {
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState("");
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const { user, exportWallet, getAccessToken } = usePrivy();

  const { data, isError, isLoading } = useBalance({
    address: wallet?.address,
    chainId,
  });

  const [portfolioValue, setPortfolioValue] = useState(0);

  useEffect(() => {
    // will need to fetch this from database
    const address = wallet?.address;
    getAccessToken()
      .then((accessToken) => {
        return getUserPortfolioValue(address, accessToken);
      })
      .then((result) => {
        setPortfolioValue(result.data);
      })
      .catch((err) => console.log("Error retrieving portfolio value", err));
  }, [wallet?.address]);

  const truncateString = (str) => {
    if (str) {
      const maxLength = 8;
      const truncatedStr =
        str.length > maxLength ? str.slice(0, 4) + "..." + str.slice(-4) : str;
      return truncatedStr;
    } else {
      return "";
    }
  };
  const truncatedWalletAddr = truncateString(wallet?.address);

  const { logout } = useLogout({
    onSuccess: () => {
      console.log("User logged out");
      navigate("/Login");
    },
  });

  const [depositVisible, setDepositVisible] = useState(false);
  const handleDepositClick = () => {
    setDepositVisible(true);
  };
  const handleDepositClose = () => {
    setDepositVisible(false);
  };

  const [withdrawVisible, setWithdrawVisible] = useState(false);
  const handleWithdrawClick = () => {
    setWithdrawVisible(true);
  };
  const handleWithdrawClose = () => {
    setWithdrawVisible(false);
  };

  const CustomTransition = (props) => {
    return <Zoom {...props} timeout={{ enter: 250, exit: 250 }} />;
  };

  const handleCopy = (walletAddr, containerElement = document.body) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard
        .writeText(walletAddr)
        .then(() => {
          setAlertMessage("Copied wallet address to your clipboard!");
          setSnackbarOpen(true);
        })
        .catch((error) => {
          console.error(error);
          setAlertMessage("Failed to copy wallet address.");
          setSnackbarOpen(true);
        });
    } else {
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement("textarea");
      textArea.value = walletAddr;

      // Move textarea out of the viewport so it's not visible
      textArea.style.position = "absolute";
      textArea.style.left = "-999999px";

      containerElement.prepend(textArea);
      textArea.select();

      try {
        document.execCommand("copy");
        setAlertMessage("Copied wallet address to your clipboard!");
        setSnackbarOpen(true);
      } catch (error) {
        console.error(error);
        setAlertMessage("Failed to copy wallet address.");
        setSnackbarOpen(true);
      } finally {
        textArea.remove();
      }
    }
  };

  const handleSnackbarClose = () => {
    setAlertMessage("");
    setSnackbarOpen(false);
  };

  const signInWithTwitter = () => {
    const provider = new TwitterAuthProvider();
    signInWithPopup(authentication, provider)
      .then(async (res) => {
        const twitterUser = res._tokenResponse;
        const updatedFields = {
          twitterInfo: {
            photoUrl: twitterUser.photoUrl.replace("_normal", ""),
            displayName: twitterUser.displayName,
            screenName: twitterUser.screenName,
          },
        };
        const accessToken = await getAccessToken();
        const response = await updateUser(
          user.id.split(":")[2],
          updatedFields,
          accessToken
        );
        setUserInfo(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <AppHeader appBarTitle={"Account"} userInfo={userInfo} canGoBack={true} />
      <Container sx={{ marginTop: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                paddingLeft: 2,
                paddingRight: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50vw",
                  overflowX: "hidden",
                }}>
                <Avatar
                  sx={{ width: 100, height: 100 }}
                  src={
                    userInfo.twitterInfo ? userInfo.twitterInfo.photoUrl : ""
                  }
                  alt='Profile Picture'
                />
                <Box sx={{ marginTop: 2 }}>
                  {userInfo.twitterInfo && (
                    <Typography variant='h6'>
                      {userInfo.twitterInfo.displayName}
                    </Typography>
                  )}
                  {userInfo.twitterInfo && (
                    <Typography variant='body2'>
                      @{userInfo.twitterInfo.screenName}
                    </Typography>
                  )}
                  {!userInfo.twitterInfo && (
                    <SignInButton onClick={signInWithTwitter}>
                      Link Twitter
                    </SignInButton>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  alignItems: "right",
                  width: "50vw",
                }}>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}>
                  <Typography variant='body1'>Wallet Balance</Typography>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}>
                    <FontAwesomeIcon
                      icon={faEthereum}
                      style={{ color: "#000000", fontSize: "16px" }}
                    />
                    <Typography variant='h6' sx={{ marginLeft: "5px" }}>
                      {/* {Number(data?.formatted).toFixed(2)} {data?.symbol} */}
                      {Number(data?.formatted).toFixed(3)}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}>
                  <Typography variant='body1'>Portfolio Value</Typography>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}>
                    <InventoryIcon sx={{ fontSize: "16px" }} />
                    <Typography variant='h6' sx={{ marginLeft: "3px" }}>
                      {portfolioValue && portfolioValue.toFixed(3)}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}>
                  <Typography variant='body1'>Wallet Address</Typography>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingLeft: "25px",
                    }}>
                    <Typography variant='body1'>
                      {truncatedWalletAddr}
                    </Typography>
                    <Button
                      onClick={() => handleCopy(wallet ? wallet.address : "")}
                      style={{ maxWidth: "48px", minWidth: "48px" }}
                      startIcon={<ContentCopyIcon />}
                    />
                    <Snackbar
                      key={alertMessage}
                      open={isSnackbarOpen}
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      autoHideDuration={1500}
                      onClose={handleSnackbarClose}
                      TransitionComponent={CustomTransition}>
                      <Alert
                        variant='outlined'
                        severity={
                          alertMessage.startsWith("Failed")
                            ? "error"
                            : "success"
                        }
                        sx={{ bgcolor: "background.paper" }}>
                        {alertMessage}
                      </Alert>
                    </Snackbar>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Divider variant='middle' />
            <Box
              sx={{
                paddingTop: 2,
                paddingLeft: 2,
                paddingRight: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}>
              <Box sx={{ padding: 2 }}>
                <StyledButton
                  onClick={handleDepositClick}
                  disabled={!wallet}
                  disableFocusRipple>
                  Deposit on chain
                </StyledButton>
              </Box>
              <Box sx={{ padding: 2 }}>
                <StyledButton
                  onClick={handleWithdrawClick}
                  disabled={!wallet}
                  disableFocusRipple>
                  Withdraw
                </StyledButton>
              </Box>
              <Box sx={{ padding: 2 }}>
                <StyledButton
                  onClick={exportWallet}
                  disabled={!wallet}
                  disableFocusRipple>
                  Export wallet
                </StyledButton>
              </Box>
              <Box sx={{ padding: 2 }}>
                <StyledButton onClick={logout} disableFocusRipple>
                  Log out
                </StyledButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Deposit
        isOpen={depositVisible}
        handleClose={handleDepositClose}
        wallet={wallet}
        handleCopy={handleCopy}
      />
      <Withdraw
        isOpen={withdrawVisible}
        handleClose={handleWithdrawClose}
        wallet={wallet}
        balance={data}
      />
    </>
  );
};

export default WalletPage;

const SignInButton = styled(Button)({
  width: "100%",
  background: blue[600],
  padding: "8px",
  borderRadius: 60,
  border: "none",
  fontSize: "10px",
  color: "white",
  "&:hover": { background: blue[800] },
});

const StyledButton = styled(Button)({
  width: "100%",
  background: blue[600],
  padding: "12px",
  borderRadius: 60,
  border: "none",
  fontSize: "14px",
  color: "white",
  "&:hover": { background: blue[800] },
});
