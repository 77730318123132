import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA_YL0KG6nKRaL0arblgHKlGq8gZ_jfx2g",
  authDomain: "memenews-75bfd.firebaseapp.com",
  projectId: "memenews-75bfd",
  storageBucket: "memenews-75bfd.appspot.com",
  messagingSenderId: "869837489991",
  appId: "1:869837489991:web:5b6110e2d51e042eb9ae62",
  measurementId: "G-QBTWQXKRD5"
};

const app = initializeApp(firebaseConfig);

export const authentication = getAuth(app);