import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Avatar,
  Button,
  TextField,
} from "@mui/material";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import { blue, red } from "@mui/material/colors";
import {
  getMemeSupplyFromMemeId,
  getMemeBalanceFromMemeId,
  getMemeBuyPrice,
  getMemeSellPrice,
  getBuyMemeTx,
  getSellMemeTx,
  chainId,
} from "../util/contracts/memeFunctions";
import { weiToEth, isValidInteger } from "../util/util";

import { usePrivy } from "@privy-io/react-auth";
import { useWallets } from "@privy-io/react-auth";
import { useNavigate } from "react-router-dom";

const ActionComponent = ({
  isBuy,
  memeId,
  amount,
  setAmount,
  price,
  wallet,
  ticker,
  supply,
  handleClose,
}) => {
  const navigate = useNavigate();
  const { sendTransaction } = usePrivy();

  const handleTransaction = async () => {
    // https://docs.privy.io/guide/frontend/embedded/usage/transacting
    // const unsignedTx = {
    //   to: "0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045",
    //   from: wallet.address,
    //   chainId: 1,
    //   value: "0x3B9ACA00",
    // };
    const sender = wallet.address;
    let unsignedTx;
    if (isBuy) {
      unsignedTx = await getBuyMemeTx({
        memeId,
        amount,
        sender,
        price,
      });
    } else {
      unsignedTx = await getSellMemeTx({ memeId, amount, sender });
    }
    console.log("unsignedTx: ", unsignedTx);
    unsignedTx.chainId = chainId;

    const text = amount > 1 ? "memes" : "meme";
    const uiConfig = {
      header: isBuy
        ? `Buy ${amount} ${ticker} ${text}`
        : `Sell ${amount} ${ticker} ${text}`,
      description:
        "Holding this meme could qualify you for a bounty if similar news emerges in the future.",
      buttonText: "Meme Now",
    };

    sendTransaction(unsignedTx, uiConfig)
      .then((txReceipt) => {
        console.log(txReceipt);
        handleClose();
        navigate("/Meming");
        window.location.reload();
      })
      .catch((error) => {
        handleClose();
        window.location.reload();
        console.error(error);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginBottom: "5px",
      }}>
      <Box
        display='flex'
        alignItems='center'
        // justifyContent='space-between'
        gap='30px'>
        <Button
          onClick={handleTransaction}
          disabled={!wallet || !isValidInteger(amount)}
          sx={{
            backgroundColor: isBuy ? blue[500] : red[500],
            color: "white",
            padding: "8px 12px",
            borderRadius: "50px",
            marginBottom: "10px",
            marginTop: "10px",
            width: "70%",
          }}>
          {isBuy ? "Buy" : "Sell"}
          {isBuy ? <ArrowCircleUpIcon /> : <ArrowCircleDownIcon />}{" "}
        </Button>
        <TextField
          type='number'
          value={amount}
          sx={{
            borderRadius: "100%",
            width: "30%",
          }}
          label='Amount'
          onChange={(e) => setAmount(e.target.value)}
          inputProps={{ min: 0 }}
          size='small'
        />
      </Box>
      {!isValidInteger(amount) ? (
        <Typography variant='caption' color={red[500]}>
          Input format needs to be an integer
        </Typography>
      ) : !isBuy && supply - amount < 2 && amount !== 0 ? (
        <Typography variant='caption' color={red[500]}>
          Last two shares cannot be sold
        </Typography>
      ) : (
        <Typography variant='caption'>
          {isBuy ? "Price" : "Receive"} {weiToEth(Number(price))} ETH
        </Typography>
      )}
    </Box>
  );
};

const BuySellMeme = ({
  isOpen,
  handleClose,
  memeId,
  ticker,
  tickerImage,
  news,
  setIsMemed,
}) => {
  const { wallets } = useWallets();
  const embeddedWallet = wallets.find(
    (wallet) => wallet.walletClientType === "privy"
  );

  const [supply, setSupply] = useState(0);
  useEffect(() => {
    if (isOpen) {
      getMemeSupplyFromMemeId(memeId)
        .then((result) => {
          const supplyNum = Number(result);
          // console.log("meme supply: ", supplyNum);
          setSupply(supplyNum);
          // will need to set this
          // setIsMemed(supplyNum > 0);
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    }
  }, [memeId, isOpen, setIsMemed]);

  const [balance, setBalance] = useState(0);
  useEffect(() => {
    if (isOpen) {
      // console.log("memeId: ", memeId);
      getMemeBalanceFromMemeId(memeId, embeddedWallet?.address)
        .then((result) => {
          const balanceNum = Number(result);
          console.log("balanceNum: ", balanceNum);
          setBalance(balanceNum);
        })
        .catch((err) => {
          setBalance(0);
          console.log("err: ", err);
        });
    }
  }, [memeId, isOpen, embeddedWallet?.address]);

  const [buyPrice, setBuyPrice] = useState(0);
  const [buyAmount, setBuyAmount] = useState(1);
  useEffect(() => {
    if (isOpen) {
      buyAmount === ""
        ? setBuyPrice(0)
        : getMemeBuyPrice(memeId, buyAmount)
            .then((result) => {
              // const price = Number(result);
              // const priceInEth = weiToEth(price);
              setBuyPrice(result);
            })
            .catch((err) => {
              console.log("err: ", err);
            });
    }
  }, [memeId, isOpen, buyAmount]);

  const [sellPrice, setSellPrice] = useState(0);
  const [sellAmount, setSellAmount] = useState(0);
  useEffect(() => {
    if (isOpen) {
      // console.log("memeId: ", memeId);
      // console.log("sellAmount: ", sellAmount);
      if (supply - sellAmount >= 2) {
        getMemeSellPrice(memeId, sellAmount)
          .then((result) => {
            // const price = Number(result);
            // const priceInEth = weiToEth(price);
            setSellPrice(result);
          })
          .catch((err) => {
            console.log("err: ", err);
            setSellPrice(0);
          });
      } else {
        setSellPrice(0);
      }
    }
  }, [memeId, isOpen, sellAmount, supply]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Box display='flex' alignItems='center' gap={1}>
          <Avatar alt='meme image' src={tickerImage} />
          <Box display='flex' flexDirection='column'>
            <Typography variant='title'>{ticker}</Typography>
            <Typography variant='caption'>{`Meming: ${balance}, Supply: ${supply}`}</Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant='body2' sx={{ marginBottom: "20px" }}>
          {news}
        </Typography>
        {/* <DialogContentText>
          A new AI tool, Gemini, has just been released by Google.
        </DialogContentText> */}
        {/* <DialogActions> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}>
          <ActionComponent
            isBuy={true}
            memeId={memeId}
            amount={buyAmount}
            setAmount={setBuyAmount}
            price={buyPrice}
            wallet={embeddedWallet}
            ticker={ticker}
            supply={supply}
            handleClose={handleClose}
          />
          {/* </DialogActions> */}
          {/* <DialogActions> */}
          {balance > 0 && (
            <ActionComponent
              isBuy={false}
              memeId={memeId}
              amount={sellAmount}
              setAmount={setSellAmount}
              price={sellPrice}
              wallet={embeddedWallet}
              ticker={ticker}
              supply={supply}
              handleClose={handleClose}
            />
          )}
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Button
            onClick={handleClose}
            sx={{
              backgroundColor: red[500],
              color: "white",
              padding: "8px 12px",
              borderRadius: "50px",
              marginBottom: "10px",
              marginTop: "10px",
              width: "100%",
            }}>
            <ArrowCircleDownIcon /> {" Meme Down"}
          </Button>
          <Typography variant='caption'>Price: {sellPrice} ETH</Typography>
        </Box> */}
        {/* </DialogActions> */}
      </DialogContent>
    </Dialog>
  );
};

export default BuySellMeme;
