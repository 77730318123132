import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/base/Button";
import { styled } from "@mui/system";
import { blue } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import { VapidPublicKey } from "../util/util";
import { postSubscriptionDetails } from "../util/backendApi";
import { Box } from "@mui/material";
import { usePrivy } from "@privy-io/react-auth";

const NotificationPage = () => {
  const { getAccessToken } = usePrivy();

  const navigate = useNavigate();

  const createSubscriptionAndSendToServer = async (registration) => {
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: VapidPublicKey,
    });
    console.log("Push notification subscription: ", subscription);
    getAccessToken().then((accessToken) => {
      postSubscriptionDetails(subscription, accessToken);
    });
    navigate("/");
  };

  const handleEnableNotification = async () => {
    if (!("PushManager" in window)) {
      console.log("Push manager not available");
      return;
    }
    if (!("Notification" in window)) {
      console.log("Notification not available");
      return;
    }
    const registration = await navigator.serviceWorker.getRegistration();
    if (Notification.permission === "granted") {
      createSubscriptionAndSendToServer(registration);
    } else {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        createSubscriptionAndSendToServer(registration);
      }
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      style={{ height: "100vh", marginTop: "150px" }}
    >
      <Grid item padding={"20px"}>
        <StyledH2> Welcome to Meme News! </StyledH2>
        <StyledH4>
          Turn on notifications to find out when memes are minted.
        </StyledH4>
        <StyledEnableButton onClick={handleEnableNotification}>
          {" "}
          Enable Notifications{" "}
        </StyledEnableButton>
        <Box
          sx={{ textAlign: "center", fontSize: "20px", color: "grey" }}
          onClick={() => {
            navigate("/");
          }}
        >
          Skip
        </Box>
      </Grid>
    </Grid>
  );
};

export default NotificationPage;

const StyledH2 = styled("h2")({
  fontSize: "24px",
  color: blue[500],
});

const StyledH4 = styled("h4")({
  fontSize: "12px",
  color: "grey",
});

const StyledEnableButton = styled(Button)({
  width: "100%",
  background: blue[600],
  padding: "20px",
  marginTop: "40px",
  marginBottom: "25px",
  borderRadius: 60,
  border: "none",
  fontSize: "20px",
  color: "white",
  "&:hover": { background: blue[800] },
});
