import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Button,
} from "@mui/material";
import Zoom from "@mui/material/Zoom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const Deposit = ({ isOpen, handleClose, wallet, handleCopy }) => {
  const truncateString = (str) => {
    if (str) {
      const maxLength = 10;
      const truncatedStr =
        str.length > maxLength ? str.slice(0, 6) + "..." + str.slice(-4) : str;
      return truncatedStr;
    } else {
      return "";
    }
  };
  const truncatedWalletAddr = truncateString(wallet?.address);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="title">Deposit ETH</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ marginBottom: "20px" }}>
          To deposit ETH to your meme.news wallet, copy your wallet address
        </Typography>
        <Box
          id="deposit-dialog"
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">{truncatedWalletAddr}</Typography>
          <Button
            onClick={() =>
              handleCopy(
                wallet ? wallet.address : "",
                document.getElementById("deposit-dialog")
              )
            }
            startIcon={<ContentCopyIcon />}
          />
        </Box>
        <Typography
          variant="body2"
          sx={{ marginTop: "20px", marginBottom: "20px" }}
        >
          and send eth from any other wallet. Note the network is currently
          Testnet Goerli.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default Deposit;
