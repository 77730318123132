import {
  UserUrl,
  InviteCodeUrl,
  LoginUrl,
  FeedUrl,
  MintUrl,
  SubscribeNotificationUrl,
  MyPortfolioUrl,
} from "./util";
import axios from "axios";
// import qs from "qs";

const getUser = async (userId, accessToken) => {
  const url = UserUrl + "/" + userId;
  const headers = { Authorization: `Bearer ${accessToken}` };
  return axios
    .get(url, { headers: headers })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("Error retrieving user info", err);
      return err;
    });
};

const addUser = async (userId, code, accessToken) => {
  const headers = { Authorization: `Bearer ${accessToken}` };
  const requestBody = {
    uid: userId,
    inviteCode: code,
  };
  return axios
    .post(LoginUrl, requestBody, { headers: headers })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("Error logging in", err);
      return err;
    });
};

const updateUser = async (userId, updatedFields, accessToken) => {
  const url = UserUrl + "/" + userId;
  const headers = { Authorization: `Bearer ${accessToken}` };
  return axios
    .post(url, updatedFields, { headers: headers })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("Error updating user", err);
      return err;
    });
};

const getInviteCode = async (inviteCode, accessToken) => {
  const url = InviteCodeUrl + "/" + inviteCode;
  const headers = { Authorization: `Bearer ${accessToken}` };
  return axios
    .get(url, { headers: headers })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("Error retrieving invite code", err);
      return err;
    });
};

const getPosts = async (requestParams, accessToken) => {
  const headers = { Authorization: `Bearer ${accessToken}` };
  return axios
    .get(FeedUrl, {
      headers: headers,
      params: requestParams,
      // paramsSerializer: qs.stringify,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("Fetching news error: ", err);
      return err;
    });
};

const mintPost = async ({
  articleId,
  memeName,
  walletAddress,
  accessToken,
}) => {
  const headers = { Authorization: `Bearer ${accessToken}` };
  const url = MintUrl + "/" + articleId;
  const requestBody = {
    memeName: memeName,
    walletAddress: walletAddress,
  };

  return axios
    .post(url, requestBody, { headers: headers })
    .then((res) => {
      // setPosts(res.data);
      // console.log("posts: ", res.data);
      // console.log("timestamp", res.data[0].pubDate._seconds);
      console.log("Res: ", res);
      return res;
    })
    .catch((err) => {
      console.log("Fetching news error: ", err);
      return err;
    });
};

const postSubscriptionDetails = async (subscription, accessToken) => {
  const headers = { Authorization: `Bearer ${accessToken}` };
  const sub = JSON.parse(JSON.stringify(subscription));
  const requestBody = {
    endpoint: sub.endpoint,
    p256dh: sub.keys.p256dh,
    auth: sub.keys.auth,
  };
  return axios
    .post(SubscribeNotificationUrl, requestBody, { headers: headers })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("Error sending notification subscription", err);
      return err;
    });
};

const getUserPortfolioValue = async (address, accessToken) => {
  const headers = { Authorization: `Bearer ${accessToken}` };
  const url = MyPortfolioUrl + "/" + address;
  return axios
    .get(url, { headers: headers })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // console.log("Error retrieving portfolio value", err);
      return err;
    });
};

export {
  getUser,
  addUser,
  updateUser,
  getInviteCode,
  getPosts,
  mintPost,
  postSubscriptionDetails,
  getUserPortfolioValue,
};
