import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Post from "./Post";
import { Box, Divider } from "@mui/material";
import "./Feed.css";
import FlipMove from "react-flip-move";
import AppHeader from "./AppHeader";
import { getPosts } from "../util/backendApi";
import { usePrivy } from "@privy-io/react-auth";
import Fab from "@mui/material/Fab";
import NavigationIcon from "@mui/icons-material/Navigation";
import { blue } from "@mui/material/colors";

function Feed({ userInfo }) {
  const { getAccessToken } = usePrivy();

  const [loadingPosts, setLoadingPosts] = useState(true);
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    setLoadingPosts(true);
    getAccessToken()
      .then((accessToken) => {
        return getPosts({}, accessToken);
      })
      .then((posts) => {
        console.log("posts: ", posts);
        setPosts(posts);
        setLoadingPosts(false);
      });
  }, []);

  const [fabVisible, setFabVisible] = useState(false);
  const scrollToTop = () => {
    document.body.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    const handleScroll = () => {
      if (document.body.scrollTop > 100) {
        setFabVisible(true);
      } else {
        setFabVisible(false);
      }
    };

    document.body.addEventListener("scroll", handleScroll);

    return () => {
      document.body.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [startY, setStartY] = useState(null);
  const [pullDistance, setPullDistance] = useState(0);
  const [refreshing, setRefreshing] = useState(false);

  const pullThreshold = 50;

  const MAX = 70;
  const k = 2;
  const addTension = (dy) => {
    return MAX * (1 - Math.exp((-k * dy) / MAX));
  };

  const handleTouchStart = (e) => {
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    if (!startY || refreshing) {
      return;
    }
    const deltaY = e.touches[0].clientY - startY;
    if (deltaY > 0 && document.body.scrollTop === 0) {
      e.preventDefault();
      setPullDistance(deltaY);
    }
    if (
      deltaY < 0 &&
      window.innerHeight + document.body.scrollTop ===
        document.body.scrollHeight
    ) {
      e.preventDefault();
      setPullDistance(deltaY);
    }
  };

  const handleTouchEnd = () => {
    if (!refreshing) {
      if (pullDistance > 0 && pullDistance > pullThreshold) {
        setRefreshing(true);
        const params = {
          endBefore: posts.length > 0 ? posts[0].articleId : null,
        };
        getAccessToken()
          .then((accessToken) => {
            return getPosts(params, accessToken);
          })
          .then((newPosts) => {
            if (newPosts.length > 0) {
              setPosts((prevPosts) => [...newPosts, ...prevPosts]);
            }
            setPullDistance(0);
            setRefreshing(false);
          });
      } else if (pullDistance < 0 && Math.abs(pullDistance) > pullThreshold) {
        setRefreshing(true);
        const params = {
          startAfter: posts.length > 0 ? posts.slice(-1)[0].articleId : null,
        };
        getAccessToken()
          .then((accessToken) => {
            return getPosts(params, accessToken);
          })
          .then((newPosts) => {
            if (newPosts.length > 0) {
              setPosts((prevPosts) => [...prevPosts, ...newPosts]);
            }
            setPullDistance(0);
            setRefreshing(false);
          });
      } else {
        setPullDistance(0);
      }
    }
    setStartY(null);
  };

  return (
    <>
      <AppHeader appBarTitle="Meme.news" userInfo={userInfo} />
      {loadingPosts ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <CircularProgress size={80} />
        </Box>
      ) : (
        <div
          className="feed"
          style={{
            marginTop:
              pullDistance > 0 ? `${addTension(pullDistance) - 40}px` : "-40px",
            transition:
              "margin-top 0.3s ease-in-out, margin-bottom 0.3s ease-in-out",
            marginBottom:
              pullDistance < 0
                ? `${addTension(Math.abs(pullDistance)) - 40}px`
                : "-40px",
          }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress size={30} sx={{ color: "grey" }} />
          </Box>
          <FlipMove>
            {posts &&
              posts.length > 0 &&
              posts.map((post) => (
                <div key={post.articleId}>
                  <Post
                    memeId={post.id}
                    articleId={post.articleId}
                    key={post.title}
                    time={post.pubDate._seconds}
                    displayName={post.source.domain}
                    summary={post.summary}
                    title={post.title}
                    tickerImage={post.imageUrl}
                    ticker={post.memeName}
                    isOnchain={post.isMemed}
                  />
                  <Divider variant="middle" />
                </div>
              ))}
          </FlipMove>
          <Box sx={{ textAlign: "center", marginTop: "10px" }}>
            <CircularProgress size={30} sx={{ color: "grey" }} />
          </Box>
          {fabVisible && (
            <Fab
              disableRipple
              color={blue[600]}
              size="large"
              sx={{
                color: "white",
                bgcolor: blue[600],
                position: "fixed",
                bottom: 100,
                right: 20,
                "&:hover": {
                  bgcolor: blue[800],
                },
              }}
              onClick={scrollToTop}
            >
              <NavigationIcon />
            </Fab>
          )}
        </div>
      )}
    </>
  );
}

export default Feed;
