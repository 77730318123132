import "./index.css";
import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { PrivyProvider } from "@privy-io/react-auth";
import { PrivyWagmiConnector } from "@privy-io/wagmi-connector";
import { goerli, mainnet } from "@wagmi/chains";
import { configureChains } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { registerServiceWorker } from "./util/swRegistration";
import { chainId, httpUrl, wsUrl } from "./util/contracts/memeFunctions";

const blastTestnetChain = {
  id: chainId, // Replace with your chain's ID
  name: "Blast Sepolia",
  network: "Blast Sepolia",
  nativeCurrency: {
    decimals: 18, // Replace with the number of decimals for your chain's native token
    name: "Eth",
    symbol: "Eth", // Replace with your native currency symbol
  },
  rpcUrls: {
    default: {
      http: [httpUrl],
      webSocket: [wsUrl],
    },
    public: {
      http: [httpUrl],
      webSocket: [wsUrl],
    },
  },
  blockExplorers: {
    default: {
      name: "Explorer",
      url: "https://testnet.blastscan.io",
    },
  },
  testnet: true, // Set to true if it's a testnet, false otherwise
};

// Supported chains and providers
// https://wagmi.sh/react/chains
// https://wagmi.sh/react/providers/configuring-chains
const configureChainsConfig = configureChains(
  [blastTestnetChain, goerli, mainnet],
  [publicProvider()]
);

const privyConfig = {
  loginMethods: ["sms", "google", "apple"],
  embeddedWallets: {
    createOnLogin: "all-users",
    noPromptOnSignature: false,
  },
  appearance: {
    theme: "light",
    accentColor: "#1976d2",
    showWalletLoginFirst: false,
    logo: "favicon.ico",
  },
  supportedChains: [blastTestnetChain],
  // legal: {
  //   termsAndConditionsUrl: "https://your-terms-and-conditions-url",
  //   privacyPolicyUrl: "https://your-privacy-policy-url",
  // },
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PrivyProvider
      appId={process.env.REACT_APP_PRIVY_APP_ID}
      config={privyConfig}>
      <PrivyWagmiConnector wagmiChainsConfig={configureChainsConfig}>
        <Router>
          <App />
        </Router>
      </PrivyWagmiConnector>
    </PrivyProvider>
  </React.StrictMode>
);

registerServiceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
