import React, { useEffect, useState } from "react";
import { usePrivy } from "@privy-io/react-auth";
import { Button } from "@mui/base/Button";
import { styled } from "@mui/system";
import { blue } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { getUser, addUser } from "../util/backendApi";

const InviteCodePage = ({ setUserInfo }) => {
  const [inviteCode, setInviteCode] = useState("");
  const [loadingUserInfo, setLoadingUserInfo] = useState(true);
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();

  const { ready, authenticated, user, getAccessToken } = usePrivy();

  const handleInviteCodeChange = (event) => {
    setInviteCode(event.target.value);
  };

  const handleSubmit = () => {
    getAccessToken()
      .then((accessToken) => {
        return addUser(user.id.split(":")[2], inviteCode, accessToken);
      })
      .then(async (response) => {
        if (response.status === 200 || response.status === 201) {
          setUserInfo(response.data);
          navigate("/Notification");
        } else {
          if (response.response.status === 400) {
            setHasError(true);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    setLoadingUserInfo(true);
    if (ready && !authenticated) {
      setLoadingUserInfo(false);
      navigate("/Login");
    }
    if (ready && authenticated) {
      getAccessToken()
        .then((accessToken) => {
          return getUser(user.id.split(":")[2], accessToken);
        })
        .then((response) => {
          if (response.status === 200) {
            setUserInfo(response.data);
            setLoadingUserInfo(false);
            navigate("/");
          } else {
            setLoadingUserInfo(false);
          }
        });
    }
  }, [ready]);

  if (loadingUserInfo) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "90vh",
        }}
      >
        <CircularProgress size={80} />
      </Box>
    );
  } else {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <Grid item padding={"20px"}>
          <StyledH2> Welcome to Meme News! </StyledH2>
          <StyledH3>
            {" "}
            Get an invite code from an existing user to sign up.
          </StyledH3>
          <StyledTextField
            variant="outlined"
            placeholder="Enter Invite Code"
            autoComplete="off"
            InputProps={{ style: { fontSize: "20px" } }}
            error={hasError}
            helperText={hasError && "Invalid invite code"}
            value={inviteCode}
            onChange={handleInviteCodeChange}
          />
          <StyledButton onClick={handleSubmit}> Submit </StyledButton>
        </Grid>
      </Grid>
    );
  }
};

export default InviteCodePage;

const StyledH2 = styled("h2")({
  fontSize: "24px",
  color: blue[500],
});

const StyledH3 = styled("h3")({
  fontSize: "18px",
  color: "grey",
  marginBottom: "3em",
});

const StyledTextField = styled(TextField)({
  fieldset: {
    borderRadius: 60,
  },
  "& input": { textAlign: "center" },
  width: "100%",
  marginTop: "40px",
  marginBottom: "40px",
});

const StyledButton = styled(Button)({
  width: "100%",
  background: blue[600],
  padding: "20px",
  marginTop: "40px",
  marginBottom: "40px",
  borderRadius: 60,
  border: "none",
  fontSize: "20px",
  color: "white",
  "&:hover": { background: blue[800] },
});
