import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Avatar,
  Button,
  TextField,
} from "@mui/material";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import { blue, red, green } from "@mui/material/colors";
import PetsIcon from "@mui/icons-material/Pets";

import myMemesData from "../data/myMemesData";
import { mintPost } from "../util/backendApi";
import { useWallets, usePrivy } from "@privy-io/react-auth";
import { getAddMemeTx } from "../util/contracts/memeFunctions";
import { chainId } from "../util/contracts/memeFunctions";
import { useNavigate } from "react-router-dom";

import CongratulationsPage from "./Congratulations";

const MemeItActionComponent = ({
  memeId,
  articleId,
  setIsMemed,
  handleClose,
  handleShowConfirmation,
  ticker,
  memeName,
  setMemeName,
  setIsSuccess,
}) => {
  const { wallets } = useWallets();
  const embeddedWallet = wallets.find(
    (wallet) => wallet.walletClientType === "privy"
  );
  const walletAddress = embeddedWallet?.address;

  const navigate = useNavigate();
  const { sendTransaction, getAccessToken } = usePrivy();
  const handleMintTransaction = async ({ memeId, mintedMemeName }) => {
    // https://docs.privy.io/guide/frontend/embedded/usage/transacting
    const sender = walletAddress;
    let unsignedTx;
    unsignedTx = await getAddMemeTx({
      memeId,
      sender,
    });
    unsignedTx.chainId = chainId;

    const uiConfig = {
      header: `Receive the first ${mintedMemeName} meme for free.`,
      description:
        "Holding this meme could qualify you for a bounty if similar news emerges in the future.",
      buttonText: "Meme Now",
    };

    sendTransaction(unsignedTx, uiConfig)
      .then((txReceipt) => {
        console.log(txReceipt);
        setIsMemed(true);
        navigate("/Meming");
      })
      .catch((error) => {
        handleClose();
        handleShowConfirmation(false);
        console.error(error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle the form submission here
    // console.log("Meme Name:", memeName);
    // console.log("walletaddress: ", walletAddress);

    // memeId and ticker should always exist together
    handleClose();
    setIsSuccess(true);
    handleShowConfirmation(true);
    if (memeId && ticker) {
      handleMintTransaction({ memeId, mintedMemeName: ticker });
    } else {
      const accessToken = await getAccessToken();
      const result = await mintPost({
        articleId,
        memeName,
        walletAddress,
        accessToken,
      });
      setMemeName("");
      // TODO: Need to gift user a share
      if (result.status === 200) {
        // setIsMemed(true);
        // TODO: Navigate to your holdings and update user holdings
        // setIsSuccess(true);
        const memeId = result.data.memeId;
        const mintedMemeName = result.data.memeName;
        handleMintTransaction({ memeId, mintedMemeName });
      } else {
        // handleClose();
        setIsSuccess(false);
        // handleShowConfirmation(true);
      }
    }
    // console.log("Result: ", result);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginBottom: "5px",
          }}>
          {!ticker && (
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              width='100%'
              marginBottom='10px'>
              <Typography
                variant='body1'
                sx={{ width: "20%", marginRight: "10px" }}>
                Ticker:
              </Typography>
              <TextField
                type='text'
                value={memeName}
                sx={{ flexGrow: 1 }}
                label='Pick a Meme Ticker'
                onChange={(e) => setMemeName(e.target.value)}
                size='small'
                required
              />
            </Box>
          )}
          <Button
            type='submit'
            fullWidth
            disabled={!embeddedWallet}
            sx={{
              backgroundColor: green[500],
              color: "white",
              padding: "8px 12px",
              borderRadius: "50px",
              marginBottom: "10px",
            }}>
            Buy
            <PetsIcon />
          </Button>
          <Typography variant='caption'>
            You get the first meme for free!
          </Typography>
        </Box>
      </form>
    </>
  );
};

const MemeIt = ({
  memeId,
  articleId,
  isOpen,
  handleClose,
  ticker,
  tickerImage,
  news,
  setIsMemed,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [memeName, setMemeName] = useState("");

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>
          <Box display='flex' alignItems='center' gap={1}>
            <Avatar alt='meme image' src={tickerImage} />
            {ticker ? (
              <Typography variant='title'> {ticker} </Typography>
            ) : (
              <Typography variant='title'>Fresh Meme Alert!</Typography>
            )}

            {/* <Box display='flex' flexDirection='column'>
            <Typography variant='title'>{ticker}</Typography>
            <Typography variant='caption'>{`Meming: ${numHolding}`}</Typography>
          </Box> */}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant='body2' sx={{ marginBottom: "20px" }}>
            {news}
          </Typography>
          {/* <DialogContentText>
          A new AI tool, Gemini, has just been released by Google.
        </DialogContentText> */}
          {/* <DialogActions> */}
          <MemeItActionComponent
            memeId={memeId}
            articleId={articleId}
            setIsMemed={setIsMemed}
            handleClose={handleClose}
            handleShowConfirmation={setShowConfirmation}
            ticker={ticker}
            memeName={memeName}
            setMemeName={setMemeName}
            setIsSuccess={setIsSuccess}
            // walletAddress={embeddedWallet.address}
          />
        </DialogContent>
      </Dialog>
      {showConfirmation && (
        <CongratulationsPage
          isSuccess={isSuccess}
          handleClose={() => setShowConfirmation(false)}
          memeName={memeName}
          isOpen={showConfirmation}
        />
      )}
    </>
  );
};

export default MemeIt;
