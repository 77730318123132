const Memes = {
  TRENDING: "Trending",
  MEMING: "Meming",
};

// const baseUrl = "http://localhost:5000/memenews-75bfd/us-central1/api";
const baseUrl = "https://us-central1-memenews-75bfd.cloudfunctions.net/api";

const FeedUrl = `${baseUrl}/posts`;
const LoginUrl = `${baseUrl}/login`;
const UserUrl = `${baseUrl}/user`;
const InviteCodeUrl = `${baseUrl}/invite-code`;
const MintUrl = `${baseUrl}/mint`;
const SubscribeNotificationUrl = `${baseUrl}/subscribe`;
const TrendingUrl = `${baseUrl}/memes/trending`;
const MyMemesUrl = `${baseUrl}/holdings/user`;
const MyPortfolioUrl = `${baseUrl}/holdings/value`;

const urlBase64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const VapidPublicKey = urlBase64ToUint8Array(
  "BEL6Od9TmrjPhFdUEc3QDg9rnyDgxb4LbEKopPvw8NpjNA88X-T2ooB-hpDoPdSqfqdAvE_eQuxBNhSQpJNKGjE"
);

const timeSince = (unixTimestamp) => {
  const pastDate = new Date(unixTimestamp * 1000); // Convert to milliseconds
  const now = new Date();
  const timeDiff = now - pastDate;

  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (minutes < 60) {
    return `${minutes}m`;
  } else if (hours < 24) {
    return `${hours}h`;
  } else if (days < 7) {
    return `${days} day${days > 1 ? "s" : ""}`;
  } else if (weeks < 4) {
    return `${weeks} week${weeks > 1 ? "s" : ""}`;
  } else if (months < 12) {
    return `${months} month${months > 1 ? "s" : ""}`;
  } else {
    return `${years} year${years > 1 ? "s" : ""}`;
  }
};

const weiToEth = (gwei) => {
  return gwei / 1e18;
};

// const constant = 16000;
const constant = 640000;

const priceFromSupply = (supply) => {
  return ((supply * supply) / constant).toFixed(5).replace(/\.?0+$/, "");
};

const mcapFromSupply = (supply) => {
  return (((supply * supply) / constant) * supply)
    .toFixed(5)
    .replace(/\.?0+$/, "");
};

const isValidInteger = (str) => {
  // Regular expression for an integer
  // It matches an optional '+' or '-' at the start and numeric characters afterwards
  const regex = /^[+-]?[0-9]+$/;
  return regex.test(str);
};

// const main = () => {
//   // Example usage
//   const pastTimestamp = 1703832941; // Example Unix timestamp (seconds since epoch)
//   console.log(timeSince(pastTimestamp));
// };

// main();

export {
  Memes,
  LoginUrl,
  InviteCodeUrl,
  FeedUrl,
  UserUrl,
  MintUrl,
  TrendingUrl,
  MyMemesUrl,
  SubscribeNotificationUrl,
  VapidPublicKey,
  MyPortfolioUrl,
  timeSince,
  weiToEth,
  priceFromSupply,
  mcapFromSupply,
  isValidInteger,
};
