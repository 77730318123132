import React, { useEffect } from "react";
import "./StockList.css"; // Import your CSS file with similar styles
import Stock from "./Stock";
import { Stack, Container } from "@mui/material";
import { useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import AppHeader from "./AppHeader";
import { Memes } from "../util/util";
import { TrendingUrl } from "../util/util";
import axios from "axios";
import { usePrivy } from "@privy-io/react-auth";

function TrendingMemes({ userInfo }) {
  const { getAccessToken } = usePrivy();
  const [news, setNews] = useState([]);
  const [loadingNews, setLoadingNews] = useState(true);
  useEffect(() => {
    // will need to fetch this from database
    setLoadingNews(true);
    getAccessToken()
      .then((accessToken) => {
        const headers = { Authorization: `Bearer ${accessToken}` };
        return axios.get(TrendingUrl, { headers: headers });
      })
      .then((result) => {
        setNews(result.data);
        setLoadingNews(false);
      })
      .catch((err) => console.log("fetching trending memes err: ", err));
  }, []);

  // const [prices, setPrices] = useState([]);
  // useEffect(() => {
  //   // will need to fetch prices from database
  //   setPrices([0.4, 3.1, 10, 1.2, 0.015]);
  // }, []);

  // const [holdings, setHoldings] = useState([]);
  // useEffect(() => {
  //   // will need to fetch holdings from database
  //   setHoldings([0, 9, 3, 0, 1]);
  // }, []);

  // const [tabValue, setTabValue] = useState(0);
  // const [memeType, setMemeType] = useState(Memes.TRENDING);

  // const handleChange = (event, newValue) => {
  //   setTabValue(newValue);
  //   if (newValue === 0) {
  //     setNews(newsData);
  //     setPrices([0.4, 3.1, 10, 1.2, 0.015]);
  //     setHoldings([0, 9, 3, 0, 1]);
  //     setMemeType(Memes.TRENDING);
  //   } else if (newValue === 1) {
  //     setNews(myMemesData);
  //     setPrices([3.1, 10, 0.015]);
  //     setHoldings([9, 3, 1]);
  //     setMemeType(Memes.MEMING);
  //   }
  // };

  return (
    <>
      <AppHeader appBarTitle={"Trending"} userInfo={userInfo} />
      {loadingNews ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <CircularProgress size={80} />
        </Box>
      ) : (
        <Container maxWidth="xl" disableGutters>
          {/* Remove padding using Container */}
          {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabValue} onChange={handleChange} variant='fullWidth'>
            <Tab label='Trending' />
            <Tab label='Meming' />
          </Tabs>
        </Box> */}
          <Stack
            direction="column"
            spacing={1}
            sx={{
              // minHeight: "100vh",
              // maxHeight: "100vh", // Max height of the viewport
              overflowY: "auto", // Enables vertical scrolling if content overflows
              justifyContent: "start", // Align items to the top
              pb: 1,
            }}
          >
            {/* The Stack component to stack your Stock components */}
            {news.length > 0 &&
              news.map((data, index) => (
                <Stock
                  key={index}
                  memeId={data.memeId}
                  title={data.title}
                  ticker={data.memeName}
                  tickerImage={data.imageUrl}
                  supply={data.supply}
                  // holding={holdings[index]}
                  memeType={Memes.TRENDING}
                />
              ))}
          </Stack>
        </Container>
      )}
    </>
  );
}

export default TrendingMemes;
