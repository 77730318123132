import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { usePrivy } from "@privy-io/react-auth";
import { Button } from "@mui/base/Button";
import { styled } from "@mui/system";
import { blue } from "@mui/material/colors";
import Grid from "@mui/material/Grid";

const CreateWalletPage = ({ wallet }) => {
  const navigate = useNavigate();
  const { createWallet } = usePrivy();

  useEffect(() => {
    if (wallet) {
      navigate("/");
    }
  }, [wallet, createWallet]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: "100vh" }}
    >
      <Grid item padding={"20px"}>
        <StyledH3>
          {" "}
          Looks like you do not have a wallet with us yet. Click the button to
          create one now!{" "}
        </StyledH3>
        <StyledButton onClick={createWallet}> Create my wallet </StyledButton>
      </Grid>
    </Grid>
  );
};

export default CreateWalletPage;

const StyledH3 = styled("h3")({
  fontSize: "18px",
  color: "grey",
  marginBottom: "3em",
});

const StyledButton = styled(Button)({
  width: "100%",
  background: blue[600],
  padding: "20px",
  marginTop: "40px",
  marginBottom: "40px",
  borderRadius: 60,
  border: "none",
  fontSize: "20px",
  color: "white",
  "&:hover": { background: blue[800] },
});
