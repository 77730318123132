import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Avatar,
  Box,
} from "@mui/material";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import InventoryIcon from "@mui/icons-material/Inventory";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useBalance } from "wagmi";
import { usePrivy, useWallets } from "@privy-io/react-auth";
import { chainId } from "../util/contracts/memeFunctions";
import { getUserPortfolioValue } from "../util/backendApi";

const AppHeader = ({ appBarTitle, userInfo, canGoBack = false }) => {
  const navigate = useNavigate();
  const { getAccessToken } = usePrivy();
  const { wallets } = useWallets();
  const embeddedWallet = wallets.find(
    (wallet) => wallet.walletClientType === "privy"
  );
  const { data, isError, isLoading } = useBalance({
    address: embeddedWallet?.address,
    chainId: chainId,
  });

  const [portfolioValue, setPortfolioValue] = useState(0);
  useEffect(() => {
    // will need to fetch this from database
    const address = embeddedWallet?.address;
    getAccessToken()
      .then((accessToken) => {
        return getUserPortfolioValue(address, accessToken);
      })
      .then((result) => {
        setPortfolioValue(result.data);
      })
      .catch((err) => console.log("Error retrieving portfolio value", err));
  }, [embeddedWallet?.address]);

  const onClickRefresh = () => {
    window.location.reload();
  };

  return (
    <>
      <AppBar
        position='fixed'
        style={{
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
        }}>
        <Toolbar>
          {canGoBack && (
            <IconButton
              size='large'
              edge='start'
              color='inherit'
              aria-label='menu'
              sx={{ mr: 2 }}
              onClick={() => navigate(-1)}>
              <ArrowBackIosIcon />
            </IconButton>
          )}
          <Typography variant='h6' onClick={onClickRefresh}>
            {appBarTitle}
          </Typography>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginLeft: "auto",
              minWidth: 105,
            }}>
            <StyledButton
              onClick={() => {
                navigate("/Wallet");
              }}
              variant='outlined'
              sx={{ marginRight: "16px" }}>
              <FontAwesomeIcon
                icon={faEthereum}
                style={{ color: "#ffffff", fontSize: "12px" }}
              />
              <Typography
                variant='body2'
                sx={{ marginLeft: "4px", marginRight: "4px" }}>
                {Number(data?.formatted).toFixed(3)}
              </Typography>
              <InventoryIcon sx={{ color: "#ffffff", fontSize: "12px" }} />
              <Typography variant='body2' sx={{ marginLeft: "2px" }}>
                {portfolioValue && portfolioValue.toFixed(2)}
              </Typography>
            </StyledButton>
            <Button
              sx={{ minWidth: 32, paddingLeft: 0, paddingRight: 0 }}
              onClick={() => {
                navigate("/Airdrop");
              }}>
              <Avatar
                sx={{ width: 32, height: 32 }}
                src={userInfo.twitterInfo ? userInfo.twitterInfo.photoUrl : ""}
                alt='Small User Avatar'
              />
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
};

export default AppHeader;

const StyledButton = styled(Button)({
  paddingLeft: "5px",
  paddingRight: "5px",
  minWidth: "58px",
  borderRadius: 60,
  borderColor: "white",
  color: "white",
  "&:hover": { borderColor: "white" },
});
